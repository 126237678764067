import React from "react"
import styles from "./block-for.module.scss"


const FeatureBlocksComponent = ({ title }) => (
  <div className={styles.blocks}>
    <div className="container">
      <div className={styles.head}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.cols}>
        <div className={`${styles.col} ${styles.purple}`}>
          <div className={styles.subtitle}>Для клиентов и лидов</div>
          <ul className={styles.list}>
            <li>Запись на занятие/услугу внутри системы, через интеграции или виджеты. </li>
            <li>Отмена записи.</li>
            <li>Напоминание о предстоящем занятии/визите.</li>
            <li>Перенос занятия/визита.</li>
            <li>Отмена занятия/визита.</li>
            <li>Окончание срока действия абонемента (дополнительные опции: за 7 дней, за 3 дня, за 1 день, сегодня). </li>
            <li>Абонемент, истекающий по остатку занятий (дополнительные опции: 3 занятия, 2 занятия, 1 занятие). </li>
          </ul>
        </div>
        <div className={`${styles.col} ${styles.blue}`}>
          <div className={styles.subtitle}>Для сотрудников</div>
          <ul className={styles.list}>
            <li>Новый лид с интеграций и виджетов.</li>
            <li>Запись на занятие через виджет Онлайн-расписание.</li>
            <li>Пополнение баланса клиента через Личный кабинет.</li>
            <li>День рождения клиента (дополнительные опции, которые помещаются в карандаш: за 7 дней, за 3 дня, за 1 день, сегодня).</li>
            <li>Окончание срока действия абонемента (дополнительные опции: за 7 дней, за 3 дня, за 1 день, сегодня).</li>
            <li>Абонемент, истекающий по остатку занятий (дополнительные опции: 3 занятия, 2 занятия, 1 занятие).</li>
            <li>Абонемент закончился.</li>
            <li>Отмена занятия/визита.</li>
            <li>Перенос занятия/визита.</li>
            <li>Поставлена задача.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default FeatureBlocksComponent
